import * as UserActions from '../actions/user.actions';

const initialState = {
    role: [],//guest
    data: {
        'displayName': '',
    }
};

const user = (state = initialState, action) => {
    switch ( action.type )
    {
        case UserActions.AUTH_SET_USER_DATA:
        {
            return {
                ...initialState,
                ...(action.payload || {})
            };
        }
        case UserActions.AUTH_REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }
        case UserActions.AUTH_USER_LOGGED_OUT:
        {
            return initialState;
        }
        default:
        {
            return state
        }
    }
};

export default user;
