import history from '@history';
import {setDefaultSettings, setInitialSettings} from 'app/store/actions/fuse';
import _ from '@lodash';
import * as Actions from 'app/store/actions';
import * as ActionPlanActions from 'app/main/apps/action-plan/store/actions';
import * as HSSEActions from './hsse.actions';
import rlaService from 'app/services/rlaService';

export const AUTH_SET_USER_DATA = '[USER] SET DATA';
export const AUTH_REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const AUTH_USER_LOGGED_OUT = '[USER] LOGGED OUT';

/**
 * Set User Data
 */
export function setUserData(user)
{
    return (dispatch) => {

        /*
        Set User Settings
         */
        dispatch(setDefaultSettings(user.data.settings));

        /*
        Set User Data
         */
        dispatch({
            type: AUTH_SET_USER_DATA,
            payload: user
        })
    }
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings)
{
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, {data: {settings}});

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts)
{
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}

/**
 * Remove User Data
 */
export function removeUserData()
{
    return {
        type: AUTH_REMOVE_USER_DATA
    }
}

/**
 * Logout
 */
export function logoutUser() {
    return async (dispatch, getState) => {
        const user = getState().auth.user;

        if (!user.role || user.role.length === 0) {
            return null;
        }

        history.push({ pathname: '/' });

        await rlaService.logout();

        dispatch(setInitialSettings());
        dispatch(HSSEActions.setStationList([]));
        
        dispatch({ type: AUTH_USER_LOGGED_OUT });
        dispatch({ type: HSSEActions.USER_LOGGED_OUT });
        dispatch({ type: ActionPlanActions.USER_LOGGED_OUT });

        return Promise.resolve();
    };
}

/**
 * Update User Data
 */
function updateUserData(user)
{
    if ( !user.role || user.role.length === 0 )// is guest
    {
        return;
    }

    switch ( user.from )
    {
        default:
        {
            rlaService.updateUserData(user)
                .then(() => {
                    console.log("User data saved with API")
                    // store.dispatch(Actions.showMessage({message: "User data saved with api"}));
                })
                .catch(error => {
                    console.error(error.message)
                    // store.dispatch(Actions.showMessage({message: error.message}));
                });
            break;
        }
    }
}
