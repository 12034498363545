import axios from 'axios';
import FuseUtils from '@fuse/FuseUtils';

const RLA_ACCESS_TOKEN = 'rla_access_token';


class rlaService extends FuseUtils.EventEmitter {

    init()
    {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                if (err.response !== undefined && err.response.status === 401 && 
                    err.config && !err.config.__isRetryRequest )
                {
                    // Detect specific errors from Node server
                    var errMesg = 'Please log in'
                    const serverError = err.response.data.serverError
                    if (serverError !== undefined) {
                        errMesg = serverError
                    }
                    // if you ever get an unauthorized response, logout the user
                    this.emit('onAutoLogout', null);
                    this.setSession(null);
                }
                throw err;
            });
        });
    };

    handleAuthentication = () => {

        let access_token = this.getAccessToken();

        if ( !access_token )
        {
            this.emit('onNoAccessToken');

            return;
        }

        if ( this.isAuthTokenValid(access_token) )
        {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        }
        else
        {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    signInWithEmailAndPassword = (username, password) => {
        this.emit('onNoAccessToken', true);

        return new Promise((resolve, reject) => {
            axios.post('/api/auth', {
                username: username,
                password: password
            }).then(response => {
                if ( response.data.user )
                {
                    this.setSession(response.data.access_token);
                    this.emit('onSignInComplete', true);
                    resolve(response.data.user);
                }
                else
                {
                    reject(response.data.error);
                }
            }).catch(err => {
                this.emit('onAutoLogout', err.response.data.error);
                throw err
            })
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios.post('/api/auth/access-token', {
                data: {
                    access_token: this.getAccessToken()
                }
            })
                .then(response => {
                    if ( response.data.user )
                    {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    }
                    else
                    {
                        this.logout();
                        reject('Failed to login with token.');
                    }
                })
                .catch(error => {
                    this.logout();
                    reject('Session expired, please sign in');
                });
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user: user
        });
    };

    setSession = access_token => {
        if ( access_token )
        {
            localStorage.setItem(RLA_ACCESS_TOKEN, access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        }
        else
        {
            localStorage.removeItem(RLA_ACCESS_TOKEN);
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = access_token => {
        if ( !access_token )
        {
            return false;
        }
        // const decoded = jwtDecode(access_token);
        // const currentTime = Date.now() / 1000;
        // if ( decoded.exp < currentTime )
        // {
        //     console.warn('access token expired');
        //     return false;
        // }
        else
        {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem(RLA_ACCESS_TOKEN);
    };
}

const instance = new rlaService();

export default instance;
