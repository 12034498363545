import React, {useEffect, useState} from 'react';
import {Button, Hidden, Icon, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Toolbar, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector, useDispatch} from 'react-redux';
import * as AuthActions from 'app/auth/store/actions';
import * as HSSEActions from 'app/main/apps/dashboards/hsse/store/actions';
import * as DashActions from 'app/store/actions/hsse';
import {Link} from 'react-router-dom';
import {hsseRed} from '@hsse/hsse-colors';
import HSSESearch from './HSSESearch';

function UserMenu(props)
{
    const dispatch = useDispatch();
    const user = useSelector(({auth}) => auth.user ?? null);
    const hsse = useSelector(({auth}) => auth.hsse ?? null);

    const [userMenu, setUserMenu] = useState(null);
    const [stationMenu, setStationMenu] = useState(null);
    const [selectedStation, setSelectedStation] = useState(null);
    const [stationList, setStationList] = useState([]);
    const [loading, setLoading] = useState(true);

    const stationMenuClick = event => {
        setStationMenu(event.currentTarget);
    };

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    const showSummary = event => {
        dispatch(AuthActions.setSummaryStation(selectedStation))
        dispatch(DashActions.toggleSummary())
        setUserMenu(null)
    }

    const stationMenuSelect = (station) => {
        setSelectedStation(station);
        dispatch(AuthActions.setSelectedStation(station));
        setStationMenu(null);
    };

    const stationMenuClose = () => {
        setStationMenu(null);
    };

    useEffect(() => {
        if (hsse?.stationList) {
            setStationList(hsse.stationList);
            if (!hsse.selectedStation && hsse.stationList.length > 0) {
                dispatch(AuthActions.setSelectedStation(hsse.stationList[0]));
            }
            setLoading(hsse.getUserData === true)
        }
    }, [dispatch, hsse?.selectedStation, hsse?.stationList, hsse?.getUserData]);

    useEffect(() => {
        if (hsse?.selectedStation) {
            dispatch(HSSEActions.closeActionPlanPanel())
            setSelectedStation(hsse.selectedStation)
        }
    }, [dispatch, hsse?.selectedStation]);


    return (
        <React.Fragment>
            {(
                <React.Fragment>
                    <Hidden smDown>
                    <div className="flex flex-auto items-center px-0 sm:px-16">
                        <Link to={'/apps/dashboards/hsse'}
                          onClick={ev => dispatch(HSSEActions.closeActionPlanPanel())}
                          className="mx-4 p-4"
                          color="inherit">
                                <img className="h-48" alt="dashboard" src='assets/images/hsse/dashboard.png'/>
                        </Link>
                        <Link to={'/apps/dashboards/hsse'}
                            onClick={ev => dispatch(HSSEActions.closeActionPlanPanel())}
                            style={{color: hsseRed[500]}}>
                            <Typography className="flex-no-wrap px-16 py-0 sm:py-24 font-900" style={{color: hsseRed[500]}} variant="h5">HSSE Dashboard</Typography>
                        </Link>
                    </div>
                    </Hidden>

                    {
                        stationList.length === 1 ? (
                            stationList.map((station) => (
                            <div className="flex flex-grow-0 items-center justify-end">
                            <Typography
                                className="ml-16 pr-4 sm:pr-64 py-16 sm:py-24 text-14 sm:text-18 normal-case font-400 leading-none"
                                >{station.Name}
                            </Typography>

                            <Button className="h-64 pr-16 mr-16" onClick={userMenuClick}>
                                <div className="flex-col ml-12">
                                    <Typography component="span" className="w-full normal-case text-18 font-400">
                                        { user?.data.displayName}
                                    </Typography>
                                </div>
                                <Icon className="text-32 ml-12" style={{color: hsseRed[500]}} variant="action">arrow_downwards</Icon>
                            </Button>
                            <Popover
                                open={Boolean(userMenu)}
                                anchorEl={userMenu}
                                onClose={userMenuClose}
                                anchorOrigin={{
                                    vertical  : 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical  : 'top',
                                    horizontal: 'center'
                                }}
                                classes={{
                                    paper: "py-8"
                                }}
                            >
                                <MenuItem
                                    component={Link}
                                    onClick={ev => dispatch(AuthActions.logoutUser())}
                                >
                                    <ListItemIcon className="min-w-40">
                                        <Icon>exit_to_app</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="px-16" primary="Logout"/>
                                </MenuItem>
                            </Popover>
                            </div>
                        ))
                    ) : (
                        <React.Fragment>
                        <div className="flex flex-grow-0 items-center justify-end">
                        <Typography className="flex text-right pl-0 sm:pl-16 pr-16 py-0 hidden md:flex sm:py-24 text-18 normal-case font-600 leading-none">
                            Choose a service station from your cluster
                        </Typography>
                        {loading ? (
                            <div className="flex flex-grow w-224 justify-center">
                                <CircularProgress color="secondary" />
                            </div>
                         ) : (
                            <Button className="h-64 pr-16" onClick={stationMenuClick}>
                                <div className="flex-col ml-12">
                                    <Typography component="span" className="w-full normal-case text-18 font-800">
                                        {selectedStation ? selectedStation.Name : "Select station"}
                                    </Typography>
                                </div>

                                <Icon className="text-32 ml-12" style={{color: hsseRed[500]}} variant="action">arrow_downwards</Icon>
                            </Button>
                        )}
                        <HSSESearch />
                        <Popover
                            open={Boolean(stationMenu)}
                            anchorEl={stationMenu}
                            onClose={stationMenuClose}
                            anchorOrigin={{
                                vertical  : 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical  : 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: "py-8"
                            }}
                        >
                            { stationList.length > 1 && (
                                    stationList.map((station) => (
                                        <MenuItem
                                            key={station.GSAP}
                                            component={Link}
                                            onClick={(ev) => {
                                                stationMenuSelect(station);
                                            }
                                        }>
                                            <ListItemText className="px-16" primary={station.Name}/>
                                        </MenuItem>
                                    ))
                                )
                            }
                        </Popover>

                        <Button className="h-64 pr-16 mr-16" onClick={userMenuClick}>
                            <div className="flex-col ml-12">
                                <Typography component="span" className="w-full normal-case text-18 font-400">
                                    { user?.data.displayName}
                                </Typography>
                            </div>
                            <Icon className="text-32 ml-12" style={{color: hsseRed[500]}} variant="action">arrow_downwards</Icon>
                        </Button>
                        <Popover
                            open={Boolean(userMenu)}
                            anchorEl={userMenu}
                            onClose={userMenuClose}
                            anchorOrigin={{
                                vertical  : 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical  : 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: "py-8"
                            }}
                        >
                            <MenuItem
                                component={Link}
                                onClick={ev => showSummary()}
                            >
                                <ListItemIcon className="min-w-40">
                                    <Icon>view_list</Icon>
                                </ListItemIcon>
                                <ListItemText className="px-16" primary="Summary"/>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                onClick={ev => dispatch(AuthActions.logoutUser())}
                            >
                                <ListItemIcon className="min-w-40">
                                    <Icon>exit_to_app</Icon>
                                </ListItemIcon>
                                <ListItemText className="px-16" primary="Logout"/>
                            </MenuItem>
                        </Popover>

                        </div>
                        </React.Fragment>
                    )}

                    <React.Fragment>
                    <div className="flex flex-grow-0 items-center justify-end">


                    { user?.role === 'admin' &&
                        (<Toolbar className="px-16">
                            <Link to={'/apps/admin'}>
                                <IconButton className="ml-16">
                                    <Icon>settings</Icon>
                                </IconButton>
                            </Link>
                        </Toolbar>)
                    }
                    </div>
                    </React.Fragment>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default UserMenu;
